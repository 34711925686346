import { Navigate } from 'react-router-dom';
import Home from "~/features/Home/pages";
import Candidates from "~/features/Candidates/pages";
import PageNotFound from "~/features/NotFound/pages";
import Personnel from "~/features/Personnel/pages";
import Group from "~/features/Personnel/pages/Group";
import WorkingDay from "~/features/WorkingDay/pages";
import Customer from "~/features/Customer/pages";
import Contract from "~/features/Contract/pages";
import Revenue from "~/features/Revenue/pages";
import Expense from "~/features/Expense/pages";
import ExpenseGroup from "~/features/Expense/pages/group";
import ExpenseEvent from "~/features/Expense/pages/event";
import Project from "~/features/Project/pages";
import Task from "~/features/Task/pages";
import Form from "~/features/Form/pages";
import FormSabbatical from "~/features/Form/pages/Sabbatical";
import FormSalary from "~/features/Form/pages/Salary";
import FormPayment from "~/features/Form/pages/Payments";
import Permission from "~/features/Auth/pages/Permission";
import SaleEvent from "~/features/Sale/pages/Event";
import SaleConsultation from "~/features/Sale/pages/Consultation";
import SaleWorking from "~/features/Sale/pages/Working";
import SaleCommit from "~/features/Sale/pages/Commit";
import SaleKpi from "~/features/Sale/pages/Kpi";
import UploadWebsite from "~/features/Website/pages/UploadWebsite";
import CreatedWebsite from "~/features/Website/pages/CreatedWebsite";
import BuyService from "~/features/BuyService/pages";
import Report from "~/features/Report/pages";
import ReportSaleAnalytic from "~/features/Report/pages/sales/Analytic";
import ReportSalePoint from "~/features/Report/pages/sales/Point";
import ReportSaleReport from "~/features/Report/pages/sales/Report";
import ReportSaleEvent from "~/features/Report/pages/sales/Event";
import ReportPoint from "~/features/Report/pages/Point/Point";
import ReportWebsite from "~/features/Report/pages/Service/Website";
import Pay from "~/features/Pay/pages";
import RevenueReport from "~/features/Report/pages/Revenue/Revenue";
import ExtendReport from "~/features/Report/pages/Revenue/Extend";
import PayReport from "~/features/Report/pages/Pay/Pay";
import FinancialReport from "~/features/Report/pages/Financial/Financial";
import Service from "~/features/Service/pages";
import ServiceToDo from "~/features/Service/pages/ToDo";
import Extend from "~/features/Extend/pages";
import Account from "~/features/Account/pages";
import AccountInfo from "~/features/Account/pages/AccountInfo";
import AccountPassword from "~/features/Account/pages/AccountPassword";
import Papers from "~/features/Papers/pages";
import PapersNumber from "~/features/Papers/pages/PapersNumber";
import PapersType from "~/features/Papers/pages/PapersType";
import Rule from "~/features/Rules/pages";
import Document from "~/features/Documents/pages";

export const privateRoutes = [
	{ path: "/", component: Home },
	{ path: "/candidates", component: Candidates },
	{ path: "/personnel", component: Personnel },
	{ path: "/personnel/group", component: Group },
	{ path: "/working-day", component: WorkingDay },
	{ path: "/customer", component: Customer },
	{ path: "/service", component: Service },
	{ path: "/service/:status", component: Service },
	{ path: "/extend", component: Extend },
	{ path: "/contract", component: Contract },
	{ path: "/revenue", component: Revenue },
	{ path: "/expense", component: Expense },
	{ path: "/expense/group", component: ExpenseGroup },
	{ path: "/expense/event", component: ExpenseEvent },
	{ path: "/project", component: Project },
	{ path: "/project/:id", component: Project },
	{ path: "/task", component: Task },
	{ path: "/task/:contractId", component: Task },
	{ path: "/task/:contractId/:taskId", component: Task },
	{ path: "/form/sabbatical", component: FormSabbatical },
	{ path: "/form", component: Form },
	{ path: "/form/salary", component: FormSalary },
	{ path: "/form/payment", component: FormPayment },
	{ path: "/form/papers", component: Papers },
	{ path: "/form/papers-number", component: PapersNumber },
	{ path: "/form/papers-type", component: PapersType },
	{ path: "/permission", component: Permission },
	{ path: "/sale/event", component: SaleEvent },
	{ path: "/sale/consultation", component: SaleConsultation },
	{ path: "/sale/working", component: SaleWorking },
	{ path: "/sale/commit", component: SaleCommit },
	{ path: "/sale/kpi", component: SaleKpi },
	{ path: "/todo/upload-website", component: UploadWebsite },
	{ path: "/todo/upload-website/:id", component: UploadWebsite },
	{ path: "/todo/created-website", component: CreatedWebsite },
	{ path: "/todo/service-buy", component: BuyService },
	{ path: "/todo/service-buy/:id", component: BuyService },
	{ path: "/todo/service-todo", component: ServiceToDo },
	{ path: "/todo/service-todo/:id", component: ServiceToDo },
	{ path: "/pay", component: Pay },
	{ path: "/report", component: Report },
	{ path: "/report/sales/analytic", component: ReportSaleAnalytic },
	{ path: "/report/sales/point", component: ReportSalePoint },
	{ path: "/report/sales/report", component: ReportSaleReport },
	{ path: "/report/sales/event", component: ReportSaleEvent },
	{ path: "/report/revenue", component: RevenueReport },
	{ path: "/report/extend", component: ExtendReport },
	{ path: "/report/pay", component: PayReport },
	{ path: "/report/financial", component: FinancialReport },
	{ path: "/report/point", component: ReportPoint },
	{ path: "/report/website", component: ReportWebsite },
	{ path: "/account", component: Account },
	{ path: "/account/password", component: AccountPassword },
	{ path: "/account/info", component: AccountInfo },
	{ path: "/rules", component: Rule },
	{ path: "/documents", component: Document },
	{ path: "*", component: PageNotFound },
];

export function PrivateRoutes({children}) {
	// Check if user is logged in
	// If yes, show route
	// Otherwise, redirect to login page
	const isLoggedIn = Boolean(localStorage.getItem('access_token'));

	if (!isLoggedIn) return <Navigate to="/login" />;

	return children;
}