import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash"
import {notification} from "antd";
import {
	Modal,
	Button,
	Loading,
	Icon
} from "~/components";
import ActionBar from "~/layout/ActionBar";
import {
	handleRequest,
	apiError,
} from "~/utils";
import {
	useCan
} from "~/hooks";
import {
	customerApi,
	callSupportApi
} from "~/api";

import {
	customerErrorSelector,
	customerItemsSelector,
	customerLoadingSelector,
	customerPaginationSelector,
	customerFilterSelector,
	customerActions
} from "../customerSlice";

import {
	CustomerSearchBar,
	CustomerTable,
	CustomerFormAddEdit,
	CustomerFormCallSupport,
	CustomerFormTransfer
} from "../components";

function Customer() {

	const can = {
	    add : useCan('customerAdd'),
	    edit : useCan('customerEdit'),
	    editMaster : useCan('customerEditMaster'),
	    transfer : useCan('customerTransfer'),
	    support : useCan('customerSupport'),
	    delete : useCan('customerDelete'),
	}

	const dispatch  = useDispatch();

	const items     = useSelector(customerItemsSelector);

	const loading   = useSelector(customerLoadingSelector);

	const error     = useSelector(customerErrorSelector);

	const pagination = useSelector(customerPaginationSelector);

	const filter    = useSelector(customerFilterSelector);

	const [itemEdit, setItemEdit] = useState({});

	//Model
	const [openModal, setOpenModal] = useState({
		addEdit     : false,
		delete      : false,
		callSupport : false,
		transfer    : false,
	});

	const handleModalClose = (modal) => {
		openModal[modal] = false;
		setOpenModal({...openModal});
	}

	const handleModalOpen = (modal) => {
		openModal[modal] = true;
		setOpenModal({...openModal})
	}

	//Load data
	useEffect(() => {
		handleReLoading();
	}, [filter]);

	const handleReLoading = () => {
		dispatch(customerActions.fetchData(filter));
	}

	if (error) {
		notification.error({
			message: 'Lỗi', description: error
		});
	}

	//Submit
	const handleSaveItem = async (data, isEdit) => {
		let customerItem = {...data};
		let messageAction = 'Thêm mới';
		if(isEdit) {
			if (itemEdit?.id == 'undefined') {
				notification.error({message: 'Lỗi', description: 'Không có thông tin khách hàng để cập nhật'});
				return;
			}
			customerItem.id = itemEdit.id;
			messageAction = 'Cập nhật';
		}
		let [error, response] = (isEdit) ? await handleRequest(customerApi.update(customerItem)) : await handleRequest(customerApi.add(customerItem));
		let message = apiError(`${messageAction} thông tin khách hàng thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `${messageAction} thông tin khách hàng thành công`});
			if(!isEdit) {
				dispatch(customerActions.add(response.data));
			}
			else {
				dispatch(customerActions.update(response.data));
			}
			handleModalClose('addEdit')
		}
	}

	//Delete
	const handleDelete = async () => {
		if (itemEdit?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin khách hàng để xóa'});
			return;
		}
		let [error, response] = await handleRequest(customerApi.delete(itemEdit.id));
		let message = apiError(`xóa khách hàng thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `xóa khách hàng thành công`});
			dispatch(customerActions.delete(itemEdit.id));
			handleModalClose('delete')
		}
	}

	//status
	const handleStatus = async (item) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin khách hàng để cập nhật'});
			return;
		}
		let [error, response] = await handleRequest(customerApi.status(item.id));
		let message = apiError(`Cập nhật trạng thái khách hàng thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Cập nhật trạng thái khách hàng thành công`});
			item.status = response.data.status;
			dispatch(customerActions.update(item));
		}
	}

	//support
	const handleCallSupport = async (data, item, timeLineSupport, setTimeLineSupport) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin khách hàng để cập nhật'});
			return;
		}
		data.customerId = item.id;

		let [error, response] = await handleRequest(callSupportApi.add(data));

		let message = apiError(`Thêm cuộc gọi thất bại`, error, response);

		if(!message) {
			notification.success({message: 'Thành công', description: `Thêm cuộc gọi thành công`});
			if(_.isEmpty(timeLineSupport)) {
				setTimeLineSupport([response.data]);
			}
			else {
				let timeLineAdded = false;
				timeLineSupport.map((timeLine, index) => {
					if(timeLine.date == response.data.date) {
						timeLineAdded = true;
						timeLineSupport[index].list.unshift(response.data.list[0]);
					}
				})
				if(!timeLineAdded) {
					timeLineSupport.unshift(response.data);
				}
				setTimeLineSupport(timeLineSupport);
			}
			handleModalClose('callSupport')
		}
	}

	//transfer
	const handleTransfer = async (data, item) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin khách hàng để cập nhật'});
			return;
		}

		let customerItem = {...data};

		customerItem.id = item.id;

		let [error, response] = await handleRequest(customerApi.transfer(customerItem));

		let message = apiError(`Chuyển nhân viên quản lý khách hàng không thành công`, error, response);

		if(!message) {
			notification.success({message: 'Thành công', description: `Chuyển nhân viên quản lý khách hàng thành công`});
			if(response.data?.userId) {
				item.user = response.data.user;
				item.userId = response.data.userId;
				item.groupId = response.data.groupId;
			}
			if(response.data?.userShareId) {
				item.userShare = response.data.userShare;
				item.userShareId = response.data.userShareId;
				item.groupShareId = response.data.groupShareId;
			}
			dispatch(customerActions.update(item));
			handleModalClose('transfer');
		}
	}

	//Search
	const handlePaginationChange = (page) => {
		dispatch(customerActions.setFilter({...filter, page }));
	}

	const handleSearchChange = (newFilter) => {
		dispatch(customerActions.setFilterWithDebounce(newFilter));
	};

	const handleFilterChange = (key, value) => {
		const newFilter = {
			...filter,
			[key]: value,
			page: 1
		};
		dispatch(customerActions.setFilter(newFilter));
	};

	return (
		<>
			<ActionBar title={'Khách Hàng'}>
				{can.add && <Button outline leftIcon={Icon.plusCircle} onClick={() => {setItemEdit({});handleModalOpen('addEdit')}}>Thêm mới</Button>}
				<Button outline onClick={handleReLoading}>{Icon.reload}</Button>
			</ActionBar>
			<div className="container">
				<div className="content">
					{loading && <Loading/>}
					{<CustomerSearchBar filter={filter} onSearchChange={handleSearchChange} onChange={handleFilterChange} />}
					{items && <CustomerTable
							items={items}
							pagination={pagination}
							onPaginationChange={handlePaginationChange}
							handleStatus={handleStatus}
							setItemEdit={setItemEdit}
							openModal={handleModalOpen}
					/>}
				</div>
				{
					(can.add || can.edit || can.editMaster) && <Modal title="Khách hàng" visible={openModal.addEdit} onCancel={() => {handleModalClose('addEdit')}}>
						<CustomerFormAddEdit item={itemEdit} onHandleSubmit={handleSaveItem} />
					</Modal>
				}
				{
					(can.support && itemEdit?.id) && <Modal title="Hỗ trợ" size={'xl'} visible={openModal.callSupport} onCancel={() => {handleModalClose('callSupport')}}>
						<CustomerFormCallSupport item={itemEdit} onHandleSubmit={handleCallSupport}/>
					</Modal>
				}
				{
					(can.transfer && itemEdit?.id) && <Modal title="Chuyển nhân viên" visible={openModal.transfer} onCancel={() => {handleModalClose('transfer')}}>
						<CustomerFormTransfer item={itemEdit} onHandleSubmit={handleTransfer}/>
					</Modal>
				}
				{
					(can.delete && itemEdit?.id) && <Modal title="Xóa khách hàng" visible={openModal.delete} onCancel={() => {handleModalClose('delete')}}>
						<p>Bạn muốn xóa khách hàng <b>{itemEdit?.name}</b>?</p>
						<div className="d-flex justify-content-end gap modal-bottom pd-1">
							<Button white leftIcon={Icon.close} onClick={() => {handleModalClose('delete')}}> Đóng </Button>
							<Button primary leftIcon={Icon.delete} onClick={handleDelete}> Xóa </Button>
						</div>
					</Modal>
				}
			</div>
		</>
	)
}

export default Customer;