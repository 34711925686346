import {combineReducers} from "@reduxjs/toolkit";
import {authReducer} from '~/features/Auth/authSlice';
import {candidateReducer} from '~/features/Candidates/candidatesSlice';
import {personnelReducer} from '~/features/Personnel/personnelSlice';
import {groupReducer} from '~/features/Personnel/groupSlice';
import {workingDayReducer} from '~/features/WorkingDay/workingDaySlice';
import {customerReducer} from '~/features/Customer/customerSlice';
import {contractReducer} from '~/features/Contract/contractSlice';
import {revenueReducer} from '~/features/Revenue/revenueSlice';
import {expenseReducer} from '~/features/Expense/expenseSlice';
import {expenseGroupReducer} from '~/features/Expense/expenseGroupSlice';
import {expenseEventReducer} from '~/features/Expense/expenseEventSlice';
import {projectReducer} from '~/features/Project/projectSlice';
import {projectTaskReducer} from '~/features/Task/projectTaskSlice';
import {formSabbaticalReducer} from '~/features/Form/formSabbaticalSlice';
import {formSalaryReducer} from '~/features/Form/formSalarySlice';
import {formPaymentReducer} from '~/features/Form/formPaymentSlice';
import {saleEventReducer} from '~/features/Sale/saleEventSlice';
import {saleConsultationReducer} from '~/features/Sale/saleConsultationSlice';
import {saleWorkingReducer} from '~/features/Sale/saleWorkingSlice';
import {saleCommitReducer} from '~/features/Sale/saleCommitSlice';
import {uploadWebsiteReducer} from '~/features/Website/uploadWebsiteSlice';
import {createdWebsiteReducer} from '~/features/Website/createdWebsiteSlice';
import {buyServiceReducer} from '~/features/BuyService/buyServiceSlice';
import {saleReportReducer} from '~/features/Report/saleReportSlice';
import {payReducer} from '~/features/Pay/paySlice';
import {serviceReducer} from '~/features/Service/serviceSlice';
import {serviceToDoReducer} from '~/features/Service/serviceToDoSlice';
import {extendReducer} from '~/features/Extend/extendSlice';
import {papersReducer} from '~/features/Papers/papersSlice';
import {papersNumberReducer} from '~/features/Papers/papersNumberSlice';
import {papersTypeReducer} from '~/features/Papers/papersTypeSlice';
import {ruleReducer} from '~/features/Rules/RuleSlice';
import {documentReducer} from '~/features/Documents/DocumentSlice';

const rootReducer = combineReducers({
	auth        : authReducer,
	candidates  : candidateReducer,
	personnel   : personnelReducer,
	group       : groupReducer,
	workingDay  : workingDayReducer,
	customer    : customerReducer,
	contract    : contractReducer,
	revenue    : revenueReducer,
	expense    : expenseReducer,
	expenseGroup    : expenseGroupReducer,
	expenseEvent    : expenseEventReducer,
	project     : projectReducer,
	projectTask   : projectTaskReducer,
	formSabbatical: formSabbaticalReducer,
	formSalary    : formSalaryReducer,
	formPayment   : formPaymentReducer,
	saleEvent     : saleEventReducer,
	saleConsultation : saleConsultationReducer,
	saleWorking    : saleWorkingReducer,
	saleCommit     : saleCommitReducer,
	uploadWebsite  : uploadWebsiteReducer,
	createdWebsite : createdWebsiteReducer,
	buyService     : buyServiceReducer,
	saleReport   : saleReportReducer,
	pay   : payReducer,
	service   : serviceReducer,
	serviceToDo   : serviceToDoReducer,
	extend   : extendReducer,
	papers   : papersReducer,
	papersNumber : papersNumberReducer,
	papersType : papersTypeReducer,
	rule : ruleReducer,
	document : documentReducer,
});

export default rootReducer;