import {Fragment, useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {notification} from "antd";
import ActionBar from "~/layout/ActionBar";
import {Button, Loading, Modal, Icon} from "~/components";
import {
	contractErrorSelector,
	contractFilterSelector,
	contractItemsSelector,
	contractLoadingSelector,
	contractPaginationSelector,
	contractActions
} from "../contractSlice";
import {
	ContractTable,
	ContractFormAddEdit,
	ContractFormTransfer,
	ContractFormInfo,
	ContractSearchBar,
	ContractSearchMobile,
	ContractFormService,
	ContractFormRevenue
} from "../components";
import {
	apiError,
	strToTime,
	handleRequest,
} from "~/utils";
import {
	useCan,
	useDevice,
	useSocket,
	useCurrentUser,
	useGroup
} from "~/hooks";
import {
	contractApi,
	revenueApi,
	serviceApi,
	customerApi
} from "~/api";

import _ from "lodash";
import CustomerFormInfo from "../components/Forms/CustomerFormInfo";

function Contract() {

	const {isMobile} = useDevice();

	const {socket}  = useSocket();

	const userCurrent = useCurrentUser();

	const can = {
		add: useCan('contractAdd'),
		edit: useCan('contractEdit'),
		transfer: useCan('contractTransfer'),
		delete: useCan('contractDelete'),
		status: useCan('contractStatus'),
		service: useCan('contractService'),
		revenue: useCan('revenueAdd'),
	};

	const dispatch  = useDispatch();

	const items     = useSelector(contractItemsSelector);

	const loading   = useSelector(contractLoadingSelector);

	const error     = useSelector(contractErrorSelector);

	const pagination = useSelector(contractPaginationSelector);

	const filter    = useSelector(contractFilterSelector);

	const listGroup = useGroup();

	const [itemEdit, setItemEdit] = useState({});

	//Model
	const [openModal, setOpenModal] = useState({
		addEdit     : false,
		delete      : false,
		transfer    : false,
		info        : false,
		service     : false,
		revenue     : false,
		infoCustomer: false,
	});

	const handleModalOpen = (modal) => {
		openModal[modal] = true;
		setOpenModal({...openModal})
	}

	const handleModalClose = (modal) => {
		openModal[modal] = false;
		setOpenModal({...openModal});
	}

	//Load data
	useEffect(() => {
		dispatch(contractActions.fetchData(filter));
	}, [filter]);

	//Show Error
	if (error) {
		notification.error({message: 'Lỗi', description: error});
	}

	const handleReLoading = () => {
		dispatch(contractActions.fetchData(filter));
	}

	//Submit
	const handleSaveItem = async (data, isEdit) => {
		let messageAction = 'Thêm mới';
		if(isEdit) {
			if (itemEdit?.id == 'undefined') {
				notification.error({message: 'Lỗi', description: 'Không có thông tin hợp đồng để cập nhật'});
				return;
			}
			data.id = itemEdit.id;
			messageAction = 'Cập nhật';
		}
		data.signing = strToTime(data.signing);
		let [error, response] = (isEdit) ? await handleRequest(contractApi.update(data)) : await handleRequest(contractApi.add(data));
		let message = apiError(`${messageAction} thông tin hợp đồng thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `${messageAction} thông tin hợp đồng thành công`});
			if(!isEdit) {

				/*socket.emit("set-notification-contract", {
					userAvatar: userCurrent.avatar,
					userName: userCurrent.firstname +' '+ userCurrent.lastname,
					code : response.data.code,
					name : response.data.name
				});*/

				dispatch(contractActions.add(response.data));
			}
			else {
				setItemEdit(response.data);
				dispatch(contractActions.update(response.data));
			}
			handleModalClose('addEdit')
		}
	}

	//Delete
	const handleDelete = async () => {
		if (itemEdit?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin hợp đồng để xóa'});
			return;
		}
		if (itemEdit.status != 'pending') {
			notification.error({message: 'Lỗi', description: 'Hợp đồng đã duyệt không thể xóa'});
			return;
		}
		let [error, response] = await handleRequest(contractApi.delete(itemEdit.id));
		let message = apiError(`xóa hợp đồng thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `xóa hợp đồng thành công`});
			dispatch(contractActions.delete(itemEdit.id));
			handleModalClose('delete')
		}
	}

	//transfer
	const handleTransfer = async (data, item) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin hợp đồng để cập nhật'});
			return;
		}

		data.id = item.id;

		let [error, response] = await handleRequest(contractApi.transfer(data));

		let message = apiError(`Chuyển nhân viên quản lý hợp đồng không thành công`, error, response);

		if(!message) {
			notification.success({message: 'Thành công', description: `Chuyển nhân viên quản lý hợp đồng thành công`});
			if(response.data?.userId) {
				item.user = response.data.user;
				item.userId = response.data.userId;
				item.groupId = response.data.groupId;
			}
			if(response.data?.userShareId) {
				item.userShare = response.data.userShare;
				item.userShareId = response.data.userShareId;
				item.groupShareId = response.data.groupShareId;
			}
			dispatch(contractActions.update(item));
			handleModalClose('transfer');
		}
	}

	//Service
	const handleService = async (data, item) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin hợp đồng để cập nhật'});
			return;
		}
		if (item?.serviceId != 0) {
			notification.error({message: 'Lỗi', description: 'Hợp đồng này đã có dịch vụ'});
			return;
		}

		data.id = item.id;
		data.expired = strToTime(data.expired);
		let [error, response] = await handleRequest(serviceApi.add(data));
		let message = apiError(`Thêm dịch vụ cho hợp đồng không thành công`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Thêm dịch vụ cho hợp đồng thành công`});
			item.serviceId = response.data.serviceId;
			item.domain = response.data.domain;
			item.expired = response.data.expired;
			dispatch(contractActions.update(item));
			handleModalClose('service')
		}
	}

	//Revenue
	const handleRevenue = async (data, item) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin hợp đồng để thêm doanh thu'});
			return;
		}

		data.contractId = item.id;
		data.time = strToTime(data.time);
		let [error, response] = await handleRequest(revenueApi.add(data));
		let message = apiError(`Thêm doanh thu cho hợp đồng không thành công`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Thêm doanh thu cho hợp đồng thành công`});
			handleModalClose('revenue')
		}
	}

	//status
	const handleStatus = async (status) => {
		if (itemEdit?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin hợp đồng để cập nhật'});
			return;
		}
		let [error, response] = await handleRequest(contractApi.status({ id: itemEdit.id, status}));
		let message = apiError(`Cập nhật trạng thái hợp đồng thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Cập nhật trạng thái hợp đồng thành công`});
			itemEdit.status = status;
			setItemEdit(itemEdit);
			dispatch(contractActions.update(itemEdit));
			if(!_.isEmpty(response.data.notification)) {
				socket.emit("set-notification-user", response.data.notification);
			}
		}
	}

	const handleCustomerStatus = async (item) => {
		if (item?.id == 'undefined') {
			notification.error({message: 'Lỗi', description: 'Không có thông tin khách hàng để cập nhật'});
			return;
		}
		let [error, response] = await handleRequest(customerApi.status(item.id));
		let message = apiError(`Cập nhật trạng thái khách hàng thất bại`, error, response);
		if(!message) {
			notification.success({message: 'Thành công', description: `Cập nhật trạng thái khách hàng thành công`});
			let newItem = JSON.parse(JSON.stringify(itemEdit));
			newItem.customer.status = response.data.status;
			dispatch(contractActions.update(newItem));
			handleModalClose('infoCustomer')
		}
	}

	//Search
	const SearchBar = (isMobile) ? ContractSearchMobile : ContractSearchBar;

	const handlePaginationChange = (page) => {
		dispatch(contractActions.setFilter({...filter, page }));
	}

	const handleSearchChange = (newFilter) => {
		dispatch(contractActions.setFilterWithDebounce(newFilter));
	};

	const handleFilterChange = (key, value, reset = false) => {
		let newFilter = {
			...filter,
			page: 1,
		};
		if(reset) {
			newFilter.customerId = '';
			newFilter.status = '';
			newFilter.userId = '';
			newFilter.groupId = '';
			newFilter.service = '';
			newFilter.keyword = '';
		}
		newFilter = {
			...newFilter,
			[key]: value,
			page: 1
		};
		dispatch(contractActions.setFilter(newFilter));
	};

	return (
		<Fragment>
			<ActionBar title={'Hợp đồng'}>
				{can.add && <Button outline leftIcon={Icon.plusCircle} onClick={() => {setItemEdit({});handleModalOpen('addEdit')}}>{ (isMobile) ? '' : 'Thêm mới'}</Button>}
				<Button outline onClick={handleReLoading}>{Icon.reload}</Button>
			</ActionBar>
			<div className="container">
				<div className="content">
					{loading && <Loading/>}
					<SearchBar filter={filter} onSearchChange={handleSearchChange} listGroup={listGroup} onChange={handleFilterChange} />
					{items && <ContractTable
						items={items}
						pagination={pagination}
						onPaginationChange={handlePaginationChange}
						setItemEdit={setItemEdit}
						openModal={handleModalOpen}
						onChangeFilter={handleFilterChange}
						filter={filter}
						can={can}
					/>}
				</div>
				{
					(can.delete && itemEdit?.id) && <Modal title="Xóa khách hàng" visible={openModal.delete} onCancel={() => {handleModalClose('delete')}}>
						<p>Bạn muốn xóa hợp đồng <b>{itemEdit?.name}</b>?</p>
						<div className="pd-1 d-flex justify-content-end gap modal-bottom">
							<Button white leftIcon={Icon.close} onClick={() => {handleModalClose('delete')}}> Đóng </Button>
							<Button primary leftIcon={Icon.delete} onClick={handleDelete}> Xóa </Button>
						</div>
					</Modal>
				}
				{
					(can.add || can.edit) && <Modal title="Hợp đồng" visible={openModal.addEdit} onCancel={() => {handleModalClose('addEdit')}}>
						<ContractFormAddEdit item={itemEdit} onHandleSubmit={handleSaveItem} />
					</Modal>
				}
				{
					(itemEdit?.id) && <Modal zIndex={99} title="Thông tin hợp đồng" visible={openModal.info} onCancel={() => {handleModalClose('info')}}>
						<ContractFormInfo item={itemEdit} onClickStatus={handleStatus} modalOpen={handleModalOpen}/>
					</Modal>
				}
				{
					(can.transfer && itemEdit?.id) && <Modal title="Chuyển nhân viên" visible={openModal.transfer} onCancel={() => {handleModalClose('transfer')}}>
						<ContractFormTransfer item={itemEdit} onHandleSubmit={handleTransfer}/>
					</Modal>
				}
				{
					(can.service && itemEdit?.id) && <Modal title="Cộng dịch vụ" visible={openModal.service} onCancel={() => {handleModalClose('service')}}>
						<ContractFormService item={itemEdit} onHandleSubmit={handleService}/>
					</Modal>
				}
				{
					(can.revenue && itemEdit?.id && openModal.revenue) && <Modal title="Thêm doanh thu" visible={openModal.revenue} onCancel={() => {handleModalClose('revenue')}}>
						<ContractFormRevenue item={itemEdit} onHandleSubmit={handleRevenue}/>
					</Modal>
				}
				{
					(itemEdit?.customer && openModal.infoCustomer) && <Modal title="Thông tin khách hàng" visible={openModal.infoCustomer} onCancel={() => {handleModalClose('infoCustomer')}}>
						<CustomerFormInfo item={itemEdit.customer} onClickStatus={handleCustomerStatus}/>
					</Modal>
				}
			</div>
		</Fragment>
	)
}

export default Contract;