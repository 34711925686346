import Tippy from '@tippyjs/react/headless';
import {useState} from "react";
import {
	Button,
	Icon,
	PopperWrapper
} from "~/components";

function ServiceAction({item, setItemEdit, openModal, can}) {

	const [showActionBox, setShowActionBox] = useState(false);

	const buttonHandlers = {
		upgrade: () => {
			setItemEdit(item)
			openModal('upgrade');
			setShowActionBox(false)
		},
		extend: () => {
			setItemEdit(item)
			openModal('extend');
			setShowActionBox(false)
		},
		extendPa: () => {
			setItemEdit(item)
			openModal('extendPa');
			setShowActionBox(false)
		},
		edit: () => {
			setItemEdit(item)
			openModal('edit');
			setShowActionBox(false)
		},
		support: () => {
			setItemEdit(item)
			openModal('support');
			setShowActionBox(false)
		},
		domain: () => {
			setItemEdit(item)
			openModal('domain');
			setShowActionBox(false)
		},
		close: () => {
			setItemEdit(item)
			openModal('close');
			setShowActionBox(false)
		},
		temporary: () => {
			setItemEdit(item)
			openModal('temporary');
			setShowActionBox(false)
		},
		hostInfo: () => {
			setItemEdit(item)
			openModal('hostInfo');
			setShowActionBox(false)
		},
		hostAdd: () => {
			setItemEdit(item)
			openModal('hostAdd');
			setShowActionBox(false)
		},
		hostEdit: () => {
			setItemEdit(item)
			openModal('hostEdit');
			setShowActionBox(false)
		},
	};

	let countCan = 0;

	if(item.service == 'host') {
		countCan = 2;
		if(can.hostAdd) countCan++;
	}
	if(item.service != 'website') {
		countCan++;
	}
	if(item.service == 'domain') {
		if(can.extendPa) countCan++;
	}
	if(item.service != 'domain' && item.service != 'ssl') {
		if(can.domain) countCan++;
	}
	if(item.status != 'stop') {
		if(can.close) countCan++;
	}
	if(can.edit) countCan++;
	if(can.support) countCan++;

	const actionButtons = [
		(can.edit) && <Button key="edit" leftIcon={Icon.edit} onClick={buttonHandlers.edit}>Cập nhật</Button>,
		(can.support) && <Button key="support" leftIcon={Icon.headphones} onClick={buttonHandlers.support}>Gọi điện</Button>,
		(item.service == 'host') && <Button key="hostInfo" leftIcon={Icon.server} onClick={buttonHandlers.hostInfo}>Thông tin</Button>,
		(item.service == 'host' && can.hostAdd) && <Button key="hostAdd" leftIcon={Icon.plus} onClick={buttonHandlers.hostAdd}>Tạo host</Button>,
		(item.service == 'host' && can.hostAdd) && <Button key="hostEdit" leftIcon={Icon.plus} onClick={buttonHandlers.hostEdit}>Cập nhật host</Button>,
		(item.service == 'host') && <Button key="upgrade" leftIcon={Icon.upload} onClick={buttonHandlers.upgrade}>Nâng cấp</Button>,
		(item.service != 'website') && <Button key="extend" leftIcon={Icon.hourglassClock} onClick={buttonHandlers.extend}>Gia hạn</Button>,
		(item.service == 'domain' && can.extendPa) && <Button key="extendPa" leftIcon={Icon.hourglass} onClick={buttonHandlers.extendPa}>Gia hạn trên pa</Button>,
		(item.service != 'domain' && item.service != 'ssl' && can.domain) && <Button key="domain" leftIcon={Icon.earth} onClick={buttonHandlers.domain}>Tên miền</Button>,
		(can.close && item.status != 'stop') && <Button key="close" leftIcon={Icon.powerOff} onClick={buttonHandlers.close}>Đóng dịch vụ</Button>,
		(can.close && item.status == 'stop') && <Button key="temporary" leftIcon={Icon.powerOff} onClick={buttonHandlers.temporary}>Mở tạm dịch vụ</Button>,
	];

	function actionIsEmpty(obj) {
		for (let key in obj) {
			if (obj[key]) {
				return false;
			}
		}
		return true;
	}

	return (
		<div className="table-action d-flex">
			{(countCan == 1 && can.edit) && <Button leftIcon={Icon.edit} onClick={buttonHandlers.edit}>Cập nhật</Button>}
			{(countCan == 1 && can.support) && <Button leftIcon={Icon.headphones} onClick={buttonHandlers.support}>Gọi điện</Button>}
			{(countCan == 1 && item.service == 'host') && <Button leftIcon={Icon.server} onClick={buttonHandlers.hostInfo}>Thông tin</Button>}
			{(countCan == 1 && item.service == 'host' && can.hostAdd) && <Button leftIcon={Icon.plus} onClick={buttonHandlers.hostAdd}>Tạo host</Button>}
			{(countCan == 1 && item.service == 'host') && <Button leftIcon={Icon.upload} onClick={buttonHandlers.upgrade}>Nâng cấp</Button>}
			{(countCan == 1 && item.service != 'website') && <Button leftIcon={Icon.hourglassClock} onClick={buttonHandlers.extend}>Gia hạn</Button>}
			{(countCan == 1 && item.service == 'domain' && can.extendPa) && <Button leftIcon={Icon.hourglass} onClick={buttonHandlers.extendPa}>Gia hạn trên pa</Button>}
			{(countCan == 1 && item.service != 'domain' && item.service != 'ssl' && can.domain) && <Button leftIcon={Icon.earth} onClick={buttonHandlers.domain}>Tên miền</Button>}
			{(countCan == 1 && item.status != 'stop' && can.close) && <Button leftIcon={Icon.powerOff} onClick={buttonHandlers.close}>Đóng dịch vụ</Button>}
			{(countCan == 1 && item.status == 'stop' && can.close) && <Button leftIcon={Icon.powerOff} onClick={buttonHandlers.temporary}>Mở tạm dịch vụ</Button>}
			{
				(!actionIsEmpty(actionButtons) && countCan > 1) && <Tippy
					interactive placement="left" offset={[0, -10]}
					visible={showActionBox}
					onClickOutside={() => setShowActionBox(false)}
					render={() => (
						<PopperWrapper className="action-wrapper" style={{minWidth: '220px', }}>{actionButtons}</PopperWrapper>
					)}
				><div onClick={() => setShowActionBox(!showActionBox)} className="action-btn">{Icon.menuVertical}</div></Tippy>
			}
		</div>
	)
}

export default ServiceAction;